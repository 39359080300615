<template>
  <div class="hub">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('CheckLists')"
            :headers="headers"
            :tableData="$store.state.checkLists.checkLists"
            :loading="$store.state.checkLists.loading"
            createName="New-CheckList-Detail"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";

export default {
  name: "CheckList",
  components: {
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "id",
          type: "hidden",
          linkInfo: { name: "CheckList-Detail", routeKey: "id", varKey: "id" }
        },
        {
          name: "status",
          key: "published",
          type: "published",
          active: "green",
          inactive: "red"
        },
        { name: "name", key: "name", type: "" },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        {
          name: "submission results",
          key: "id",
          icon: "fas fa-eye",
          staticName: "view",
          type: "link",
          noSort: true,
          linkInfo: { name: "CheckListResult", routeKey: "id", varKey: "id" }
        },
        // {
        //   name: "submission results export",
        //   key: "id",
        //   icon: "fas fa-file-export",
        //   staticName: "export",
        //   type: "export",
        //   noSort: true,
        //   export: this.exportChecklistResult
        // },
        {
          name: "actions",
          key: "action",
          type: "action",
          noSort: true,
          actions: [
            {
              name: "clone",
              icon: "far fa-clone",
              action: (evt, check_list) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm clone checklist")}: ${
                  check_list.name
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("checkLists/cloneCheckList", {
                    id: check_list.id
                  });
                });
              }
            },
            {
              name: "delete",
              icon: "far fa-trash-alt",
              action: (evt, check_list) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm delete checklist")}: ${
                  check_list.name
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("checkLists/deleteCheckList", {
                    check_list
                  });
                });
              }
            }
          ]
        }
      ]
    };
  },
  methods: {
    exportChecklistResult(id, evt) {
      evt.stopPropagation();
      evt.preventDefault();
      this.$store.dispatch("exportData/exportChecklistResult", id);
    }
  },
  mounted() {
    this.$store.dispatch("checkLists/getAllCheckLists");
  }
};
</script>
